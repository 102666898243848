import rinmukti from './rinMukti.jpeg'

//temple
import rinmuktiT1 from './temple/rinmuktiTemple1.jpeg'
import rinmuktiT2 from './temple/rinmuktiTemple3.jpeg'



export const EpoojaImages = {
    0:rinmukti
}
export const epoojaTempleImages = {
    0 : [rinmuktiT1, rinmuktiT2]
}