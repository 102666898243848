import React, { useState } from "react";
import { MdOutlineTempleHindu } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import poojaLogo from "assets/icons/pooja.png";
import { useNavigate } from "react-router-dom";
import Footer from "components/Footer";
import { Epooja } from "Epooja";
import { EpoojaImages } from "assets/images/Epooja/EpoojaImages";
import { trackButtonClicks } from "Analytics/GoogleAnalytics";
import { Helmet } from "react-helmet";
import { HOST } from "utils/ApiRoutes";


function EPooja() {
  const navigate = useNavigate();
  const [poojas, setpoojas] = useState(Epooja)
  const handleCardClick = (pooja) => {
    trackButtonClicks("Epooja_Rinmukti_Card","custom_event", "To Book E-pooja")
    if (pooja.title) {
      navigate(`/e-pooja/${pooja.title}`, { state: pooja });
    }
  };

  return (
    <>
      <Helmet>
         <title>Avadhoot E-Pooja</title>
         <link rel="canonical" href={`${HOST}/e-pooja`}/>
         <meta name="description" content="Participate in E-Pujas from the comfort of your home with Avadhoot Journey. Limited slots available" />
      </Helmet>
      <div className="grid justify-items-center ">
        <h1 className="text-center p-3 font-bold text-orange-500 text-4xl mt-4">
          E - pooja 
        </h1>
       
        <div className="flex justify-center gap-6 flex-wrap w-full  
           h-full min-h-screen ">
          {poojas.map((pooja, ind) => {
            return (
              <div
                key={ind}
                onClick={() => handleCardClick(pooja)}
                className="pr-0 pl-0 w-72 my-8  px-3 shadow-xl hover:translate-y-[-5px] transition-transform rounded-lg md:w-[70%] cursor-pointer  h-full max-h-[460px] max-w-[350px]"
              >
                <div className="h-full px-2 flex flex-col justify-between">
                  <div className="relative overflow-hidden w-[100%] h-full rounded-lg ">
                    <img
                      className=" w-[100%] h-[100%] object-cover"
                      src={
                        EpoojaImages[pooja.id]
                          ? EpoojaImages[pooja.id]
                          : poojaLogo
                      }
                    />
                    <span className="absolute gap-2 text-[10px] rounded-[5px] font-semibold  left-0 top-0 border border-orange-400 bg-orange-400 py-1.5 px-2 text-white transition-all hover:bg-orange-500 text-center text-sm flex items-center justify-center">
                      {parseInt( (pooja.date - Date.now())/(1000*24*60*60))} days Left
                    </span>
                    <span className="absolute gap-2 rounded-[5px] font-semibold  right-[-4px] bottom-[-5px] border border-orange-400 bg-orange-400 py-1.5 px-2 text-white transition-all hover:bg-orange-500 text-center text-sm flex items-center justify-center">
                      <SlCalender /> {pooja.date?.toDateString()}
                    </span>
                  </div>
                  <div className="relative">
                    <div className="min-h-[100] mt-1">
                      <div>
                        <h3 className="text-xl font-semibold overflow-hidden ">
                          {pooja.title.length > 25
                            ? pooja.title.slice(0, 23) + "..."
                            : pooja.title}
                        </h3>
                      </div>
                      <div>
                        <h5 className="text-gray-500 overflow-hidden text-ellipsis whitespace-nowrap">
                          {pooja.short_desc}
                        </h5>
                      </div>
                      <div>
                        {pooja.place && (
                          <h5 className="text-gray-500 flex items-center mt-2 gap-2 overflow-hidden text-ellipsis whitespace-nowrap">
                            <MdOutlineTempleHindu />
                            {pooja.place}
                          </h5>
                        )}
                      </div>
                    </div>
                    <div className="w-full  h-[30px] mt-2">
                        <p className="text-red-500 font-semibold text-[14px]">Only limited slots available! Hurry up!!</p>
                    </div>
                    <div className=" py-3">
                      <button
                        onClick={() => handleCardClick(pooja)}
                        className="book_btn mb-0"
                      >
                        Book Pooja
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </>
  );
}

export default EPooja;

