import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MdOutlineTempleHindu } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import { TbReceiptRupee } from "react-icons/tb";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { getFullDatePackage } from "utils/HelperFuncs";
import { PiHandsPrayingLight } from "react-icons/pi";
import { LoadRazorPayScripts } from "utils/RazorPayScript";
import {
  CREATE_ORDER,
  GUEST_ORDER_ROUTE,
  RAZORPAY_CALLBACK,
  RAZORPAY_ORDER,
  SEND_INVOICE_MAIL,
} from "utils/ApiRoutes";
import axios from "axios";
import CustomModal from "components/CustomModal";
import { v4 } from "uuid";
import Loader from "components/Loader";
import { FaCheckCircle } from "react-icons/fa";
import toast, { Toaster } from "react-hot-toast";
import parsadImage from "../assets/images/parsadImage.jpg";
import dakshinaImage from "../assets/images/dakshinaImage.webp";
import { trackButtonClicks } from "Analytics/GoogleAnalytics";

const PayCheck = ({ isPayConfirm, isOrderPlaced }) => {
  return (
    <div>
      {isPayConfirm ? (
        <h1 className="text-center text-2xl">Placing your order</h1>
      ) : (
        <h1 className="text-center text-2xl">Confirming your payment</h1>
      )}
      {isOrderPlaced ? (
        <div className="text-center mt-2">
          <FaCheckCircle className="text-green-500 text-5xl inline-block" />
        </div>
      ) : (
        <div className="text-center mt-3 pt-2">
          <Loader
            size={21}
            speed={2}
            stroke={2}
            color={"gray"}
            bgOpacity={""}
          />
        </div>
      )}
    </div>
  );
};

function ReviewAndCheckOutEPooja() {
  const location = useLocation();
  const {
    img,
    receivedData,
    selectedCardDetails,
    usersName,
    mobileNumber,
    userEmail,
  } = location.state || {};
  const today = getFullDatePackage();
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [date, setdate] = useState(today);

  const [totalPrice, setTotalPrice] = useState(selectedCardDetails?.price);
  const [transactionFee, settransactionFee] = useState(
    parseInt(totalPrice) * 0.02
  );
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [visibleModel, setVisibleModel] = useState(false);
  const [showSecondStep, setSecondStep] = useState(false);
  const userLoggedIn = JSON.parse(sessionStorage.getItem("user"));
  const [userGotra, setUserGotra] = useState("");
  const [showAddressInput, setShowAddressInput] = useState(false);
  const [payVisible, setpayVisible] = useState(false);
  const [payCheckModal, setpayCheckModal] = useState(false);
  const [isOrderPlaced, setisOrderPlaced] = useState(false);
  const [isPayConfirm, setisPayConfirm] = useState(false);
  const gotraFocus = useRef(null);
  const [isloading, setIsloading] = useState(false);
  const [userAddress, setUserAddress] = useState({
    pinCode: "",
    city: "",
    state: "",
    houseNumber: "",
    roadNumber: "",
    landMark: "",
  });
  const scrollDown = useRef(null);

  const scrollToForm = (sectionRef) => {
    sectionRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  };

  useEffect(() => {
    if (
      !img ||
      !receivedData ||
      !selectedCardDetails ||
      !usersName ||
      !mobileNumber ||
      !userEmail
    ) {
      navigate("/");
    }
  }, [
    img,
    receivedData,
    selectedCardDetails,
    usersName,
    mobileNumber,
    userEmail,
    location,
    navigate,
  ]);

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setUserAddress((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleAddOn = (addOn) => {
    if (!selectedAddOns.includes(addOn.id)) {
      setSelectedAddOns([...selectedAddOns, addOn.id]);
      setTotalPrice((prevPrice) => Number(prevPrice) + Number(addOn.price));
      settransactionFee(parseInt(totalPrice) * 0.02);
    } else {
      setSelectedAddOns(selectedAddOns.filter((id) => id !== addOn.id));
      setTotalPrice((prevPrice) => Number(prevPrice) - Number(addOn.price));
      if (addOn.id === 0) {
        setUserAddress({
          pinCode: "",
          city: "",
          state: "",
          houseNumber: "",
          roadNumber: "",
          landMark: "",
        });
      }
    }
  };
  const handleSubmitAddress = () => {
    if (
      !userAddress.pinCode.trim() ||
      !userAddress.city.trim() ||
      !userAddress.houseNumber.trim() ||
      !userAddress.roadNumber.trim() ||
      !userAddress.landMark.trim() ||
      !userAddress.state.trim()
    ) {
      return toast.error("please fill all fields before submit.");
    }
    handleAddOn(dummyAddOnData[0]);
    setShowAddressInput(false);
    console.log("user details ", userAddress);
  };

  const handlePayment = async (getRef) => {
    if (!userGotra.trim()) {
      getRef.current.focus();
      return;
    }
    trackButtonClicks("E-puja payment btn","custom_event", "To Book E-pooja")
    setIsloading(true);
    let amount = (Number(totalPrice) + Number(transactionFee)) * 100;
    let paymentOptions = {
      amount,
      currency: "INR",
    };
    try {
      const { data } = await axios.post(RAZORPAY_ORDER, { paymentOptions });
      const res = await LoadRazorPayScripts(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      const options = {
        key: process.env.REACT_APP_RAZORPAY_LIVE_KEY,
        // key: "rzp_test_Zac4GgsLiMZzMM",
        amount,
        currency: "INR",
        name: "Avadhoot Journey Private Limited",
        description: `Payment for ${receivedData.title} `,
        order_id: data.order.id,
        handler: async function (response) {
          setIsloading(false);
          setpayCheckModal(true);
          setTimeout(() => {
            setpayVisible(!visibleModel);
          }, 10);
          const { razorpay_payment_id, razorpay_order_id, razorpay_signature } =
            response;
          try {
            const { data } = await axios.post(RAZORPAY_CALLBACK, {
              razorpay_order_id,
              razorpay_payment_id,
              razorpay_signature,
            });
            if (data.status) {
              // pay confirmed
              setisPayConfirm(true);
              if (userLoggedIn) {
                const paymentDetails = {
                  paymentStatus: "PAID",
                  method: "Razor Pay",
                  transactionId: razorpay_payment_id,
                  paymentTotal: amount,
                };
                const order = {
                  title: receivedData.title,
                  orderNumber: v4(),
                  category: "ePooja",
                  subTotal: amount,
                  total: amount,
                  userId: user?.id,
                  persons: selectedCardDetails.people,
                  personNames: usersName,
                  gotra: userGotra,
                  email: userEmail,
                  address: showAddressInput ? JSON.stringify(userAddress) : "",
                };
                const res = await axios.post(CREATE_ORDER, {
                  order,
                  paymentDetails,
                });
                setisOrderPlaced(true);
                // order placed
              } else {
                const order = {
                  title: receivedData.title,
                  amount,
                  category: "ePooja",
                  persons: selectedCardDetails.people,
                  personNames: usersName,
                  gotra: userGotra,
                  phoneNumber: mobileNumber,
                  email: userEmail,
                  address: showAddressInput ? JSON.stringify(userAddress) : "",
                  paymentStatus: "PAID",
                  transactionId: razorpay_payment_id,
                };
                const res = await axios.post(GUEST_ORDER_ROUTE, { order });
                // order placed
              }
              const sendInvoice = await axios.post(SEND_INVOICE_MAIL, {
                reciever: userEmail,
                userName: usersName[0],
                serviceTitle: receivedData.title,
                razorpay_order_id,
                persons: selectedCardDetails.people,
                totalAmount: parseInt(totalPrice),
                transactionId: razorpay_payment_id,
                transactFee: parseFloat(transactionFee),
              });
              setisOrderPlaced(true);
              setpayCheckModal(false);
              navigate("/thankyou")
            }
          } catch (error) {
            setIsloading(false);
            console.log(error);
          }
        },
        onabort:setIsloading(false),
        prefill: {
          name: userLoggedIn ? user.firstName : usersName[0],
          email: userEmail,
        },
        theme: {
          color: "#3399cc",
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      setIsloading(false);
      console.log(error);
    }
    // if (userLoggedIn) {
    //   const { $y: year, $M: month, $D: day } = date;
    //   const addOns = dummyAddOnData.filter((item) => {
    //     return selectedAddOns.includes(item.id);
    //   });
    //   sessionStorage.setItem(
    //     "currOrder",
    //     JSON.stringify({
    //       title: receivedData?.title,
    //       category: "ePooja",
    //       people: selectedCardDetails.people,
    //       date: `${day}-${month}-${year}`,
    //       subTotal: parseInt(selectedCardDetails.price),
    //       orderObj: { ...receivedData, ...selectedCardDetails },
    //       addOns,
    //     })
    //   );
    //   dispatch({
    //     type: reducerCases.SET_CURRENT_ORDER,
    //     order: {
    //       title: receivedData?.title,
    //       category: "ePooja",
    //       people: selectedCardDetails.people,
    //       date: `${day}-${month}-${year}`,
    //       subTotal: parseInt(selectedCardDetails.price),
    //       orderObj: { ...receivedData, ...selectedCardDetails },
    //       addOns,
    //     },
    //   });
    //   navigate("/order-summary");
    // } else {
    //   setShowModel(!showModel);
    //   setTimeout(() => {
    //     setVisibleModel(!visibleModel);
    //   }, 10);
    // }
  };

  const dummyAddOnData = [
    {
      id: 0,
      title: "Regular Puja Prasad",
      desc: "Puja Prasad will be delivered to the doorstep of devotees after the completion of Puja.",
      price: 199,
      img: parsadImage,
    },
    {
      id: 1,
      title: "Dakshina to Pujari and Brahmans",
      desc: "This contribution helps Panditji to earn his livelihood.",
      price: 251,
      img: dakshinaImage,
    },
  ];

  const handleCloseModel = () => {
    setVisibleModel(false);
    setTimeout(() => {
      setShowModel(false);
      setSecondStep(false);
    }, 500);
  };

  const handleLoggedInGuest = () => {
    setSecondStep(!showSecondStep);
  };

  return (
    <>
      <Toaster />
      <div className="relative">
        {payCheckModal && (
          <CustomModal
            ModalContent={
              <PayCheck
                isOrderPlaced={isOrderPlaced}
                isPayConfirm={isPayConfirm}
              />
            }
            visibleModel={payVisible}
          />
        )}
        {showModel && (
          <div
            className={`fixed top-0 left-0 w-full h-full p-4 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-500 ease-out z-50 ${
              visibleModel ? "opacity-100" : "opacity-0"
            }`}
          >
            <div
              className={`relative p-6 rounded-lg overflow-hidden shadow-lg max-w-sm w-full bg-gradient-to-b from-orange-300 to-orange-500 transition-transform duration-500 ease-out transform ${
                visibleModel ? "scale-100" : "scale-90"
              }`}
            >
              <div>
                <h3 className="text-white mt-6 font-semibold flex justify-center items-center w-full  ">
                  <PiHandsPrayingLight size={28} className="text-center" />
                </h3>
                {/* <h2>Book Pooja Now</h2> */}
              </div>
              {!showSecondStep ? (
                <div className="p-4">
                  <h3 className="text-center mb-3 font-semibold text-gray-600 md:text-lg">
                    Please login to continue Book Pooja
                  </h3>
                  <div className="flex flex-col justify-center items-center gap-6">
                    <div className="flex gap-2 justify-center items-center">
                      <span className="bg-white rounded-md p-2 font-semibold">
                        +91
                      </span>
                      <input
                        type="text"
                        maxLength={10}
                        minLength={10}
                        required
                        placeholder="Enter your mobile number"
                        className="outline-none border-none p-2 rounded font-semibold placeholder:text-gray-600 placeholder:font-light"
                      />
                    </div>
                    <button className="bg-orange-600 rounded w-[50%] p-2 text-white font-semibold hover:bg-orange-700">
                      Send OTP
                    </button>
                  </div>
                  <h4 className="text-center text-white font-semibold mt-6 text-opacity-70">
                    Click to continue as{" "}
                    <span
                      onClick={handleLoggedInGuest}
                      className="underline text-white text-opacity-100 cursor-pointer"
                    >
                      guest?
                    </span>
                  </h4>
                </div>
              ) : (
                <div className="mt-2 flex flex-col justify-center items-center gap-3 p-4">
                  <h3 className="text-opacity-80 text-gray-600">
                    <span className="font-semibold text-opacity-100 text-gray-600">
                      note
                    </span>{" "}
                    : one filed is required.
                  </h3>
                  <div className="flex flex-col justify-center items-center gap-3 w-full ">
                    <input
                      type="email"
                      placeholder="email"
                      name=""
                      id=""
                      className="p-2 rounded outline-none w-[100%] font-semibold  placeholder:font-light"
                    />
                    <span className="font-semibold mb-[-10px] mt-[-10px]">
                      OR
                    </span>
                    <input
                      type="text"
                      placeholder="whatsApp Number"
                      name=""
                      id=""
                      className="p-2 rounded outline-none w-[100%] font-semibold placeholder:font-light"
                    />
                  </div>
                  <div className="mt-1">
                    <button className="px-6 py-2 bg-orange-600 hover:bg-orange-700 rounded text-white font-semibold">
                      Continue
                    </button>
                  </div>
                  <h4 className="text-center text-white font-semibold mt-3 text-opacity-70">
                    continue with{" "}
                    <span
                      onClick={handleLoggedInGuest}
                      className="hover:underline  text-white text-opacity-100 cursor-pointer"
                    >
                      login?
                    </span>
                  </h4>
                  {/* <button className='py-2 px-4 bg-' onClick={handleLoggedInGuest}>continue login now</button> */}
                </div>
              )}

              <button
                onClick={handleCloseModel}
                className="absolute top-[-5px] right-0 bg-orange-600 py-1 px-3 text-white text-2xl"
              >
                x
              </button>
            </div>
          </div>
        )}

        <div className="font-[inter] py-6 h-full w-[100%]">
          <h2 className="text-center text-2xl mb-4">Review and Checkout</h2>
          <div className="flex flex-wrap justify-around py-4">
            <div className="h-[100%] gap-4 md:w-[55%] w-[90%]">
              <div className="mainCard mb-4 relative border h-full rounded-lg flex md:flex-row flex-col gap-4 py-4 px-4 w-[100%]">
                <img
                  src={img}
                  alt=""
                  className="md:h-[30vh] h-[40vh] w-[100%] max-h-[230px] rounded-xl md:w-[12rem] shadow-lg object-cover"
                />
                <div>
                  <h3 className="text-xl">{receivedData?.title}</h3>
                  <p className="flex gap-2 items-center">
                    <MdOutlineTempleHindu /> {receivedData?.place}
                  </p>
                  <p className="flex gap-2 items-center">
                    <SlCalender /> {receivedData?.date.toDateString()}
                  </p>
                  <p>Package: {selectedCardDetails?.title}</p>
                  <p className="flex items-center gap-2">
                    Sub-Total: <TbReceiptRupee /> {selectedCardDetails?.price}
                  </p>
                </div>
                <div className="bg-orange-500 bottom-0 right-0 md:text-[14px] text-[10px] md:p-2 px-2 rounded-l-sm absolute text-white">
                  Avadhoot Pvt. Ltd. Company
                </div>
              </div>
              {/*  */}
              <div className=" px-4 py-4 w-[100%] gap-4 border rounded">
                <div className="mb-4">
                  <h3 className="font-semibold md:text-xl">
                    Fill participant’s Gotra.
                  </h3>
                  <p className="text-gray-400 md:text-[15px] text-[11px]">
                    Gotra will be recited during the puja.
                  </p>
                </div>

                <input
                  ref={gotraFocus}
                  type="text"
                  placeholder="Gotra"
                  name="userGotra"
                  value={userGotra}
                  id=""
                  onChange={(e) => setUserGotra(e.target.value)}
                  className="border border-gray-300 rounded-lg px-4 py-3 w-full
                          focus:outline-none focus:border-orange-500 focus:ring-1
                          focus:ring-orange-500 transition duration-300"
                />
                <div className="mt-4  flex gap-2 items-center">
                  <input
                    type="checkbox"
                    name=""
                    id="gotraCheckBox"
                    onChange={(e) =>
                      e.target.checked
                        ? setUserGotra("Kashyap")
                        : setUserGotra("")
                    }
                    className="w-[20px] bg-orange-500 h-[20px] cursor-pointer "
                  />
                  <label htmlFor="gotraCheckBox" className=" w-auto px-1">
                    <span className="cursor-pointer">
                      I do not know my Gotra.
                    </span>
                  </label>
                </div>
              </div>
              <div className=" px-4 py-4 w-[100%] gap-4">
                <h3 className="text-xl font-semibold mb-4">Add On</h3>
                <div className="mb-2">
                  {dummyAddOnData.map((item, i) => (
                    <div
                      key={item.id}
                      className="border  w-full mb-2 flex flex-col justify-center items-center"
                    >
                      <div className="flex md:flex-row  flex-col items-end justify-between rounded-lg py-4 px-4 w-[100%]">
                        <div className="flex items-center md:flex-row flex-col gap-4 h-full w-[100%]">
                          <img
                            src={item.img}
                            alt={item.title}
                            className="md:max-h-[10rem] max-h-[10rem] md:max-w-[10rem] md:min-w-[10rem]  w-[100%] h-[200px] object-cover rounded-lg"
                          />
                          <div className="items-left w-full flex flex-col gap-2">
                            <span className="font-semibold text-lg mt-2">
                              {item.title}
                            </span>
                            <span>{item.desc}</span>
                            <span className="flex items-center gap-2">
                              <FaIndianRupeeSign /> {item.price}
                            </span>
                            <div className="flex justify-center items-center md:justify-end">
                              {i === 0 ? (
                                selectedAddOns.includes(item?.id) ? (
                                  <div
                                    onClick={() => handleAddOn(item)}
                                    className="bg-orange-500 hover:bg-orange-600 cursor-pointer duration-300 transition-all text-white px-8 py-2 rounded-lg md:w-[50%] min-w-[180px] w-[80%]"
                                  >
                                    <button className="w-[100%] h-[100%]">
                                      {selectedAddOns.includes(item.id)
                                        ? "Remove"
                                        : "Add"}
                                    </button>
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => {
                                      setShowAddressInput(true);
                                      scrollToForm(scrollDown);
                                    }}
                                    className="bg-orange-500 hover:bg-orange-600 cursor-pointer duration-300 transition-all text-white px-8 py-2 rounded-lg md:w-[50%] min-w-[180px] w-[80%]"
                                  >
                                    <button className="w-[100%] h-[100%]">
                                      {selectedAddOns.includes(item.id)
                                        ? "Remove"
                                        : "Add"}
                                    </button>
                                  </div>
                                )
                              ) : (
                                <div
                                  onClick={() => handleAddOn(item)}
                                  className="bg-orange-500 hover:bg-orange-600 cursor-pointer duration-300 transition-all text-white px-8 py-2 rounded-lg md:w-[50%] min-w-[180px] w-[80%]"
                                >
                                  <button className="w-[100%] h-[100%]">
                                    {selectedAddOns.includes(item.id)
                                      ? "Remove"
                                      : "Add"}
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div ref={scrollDown}>
                  {showAddressInput && (
                    <div className=" px-4 py-4 w-[100%] gap-4 border rounded">
                      <div className="mb-4">
                        <h3 className="font-semibold md:text-xl">
                          Do you want to get pooja prasad?
                        </h3>
                        <p className="text-gray-400 md:text-[15px] text-[11px]">
                          Prasad of workship will be sent within 8-10 days after
                          completion of puja
                        </p>
                        <p className="mt-4 font-semibold text-red-600 md:text-lg text-[18px]">
                          Note :{" "}
                          <span className="font-normal text-red-500 text-[15px]">
                            Only provide Indian residence address for
                            delivery of prasad
                          </span>
                        </p>
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 ">
                        <input
                          type="text"
                          placeholder="pin code"
                          name="pinCode"
                          value={userAddress.pinCode}
                          id=""
                          onChange={handleAddressChange}
                          required
                          className="border border-gray-300 rounded-lg px-4 py-3 w-full
                          focus:outline-none focus:border-orange-500 focus:ring-1
                          focus:ring-orange-500 transition duration-300"
                        />
                        <input
                          type="text"
                          placeholder="City"
                          name="city"
                          value={userAddress.city}
                          id=""
                          onChange={handleAddressChange}
                          required
                          className="border border-gray-300 rounded-lg px-4 py-3 w-full
                          focus:outline-none focus:border-orange-500 focus:ring-1
                          focus:ring-orange-500 transition duration-300"
                        />
                        <input
                          type="text"
                          placeholder="state"
                          name="state"
                          value={userAddress.state}
                          id=""
                          onChange={handleAddressChange}
                          required
                          className="border border-gray-300 rounded-lg px-4 py-3 w-full
                          focus:outline-none focus:border-orange-500 focus:ring-1
                          focus:ring-orange-500 transition duration-300"
                        />
                        <input
                          type="text"
                          placeholder="House Number"
                          name="houseNumber"
                          value={userAddress.houseNumber}
                          id=""
                          onChange={handleAddressChange}
                          required
                          className="border border-gray-300 rounded-lg px-4 py-3 w-full
                          focus:outline-none focus:border-orange-500 focus:ring-1
                          focus:ring-orange-500 transition duration-300"
                        />
                        <input
                          type="text"
                          placeholder="Road Number"
                          name="roadNumber"
                          value={userAddress.roadNumber}
                          id=""
                          onChange={handleAddressChange}
                          required
                          className="border border-gray-300 rounded-lg px-4 py-3 w-full
                          focus:outline-none focus:border-orange-500 focus:ring-1
                          focus:ring-orange-500 transition duration-300"
                        />
                        <input
                          type="text"
                          placeholder="LandMark"
                          name="landMark"
                          value={userAddress.landMark}
                          id=""
                          onChange={handleAddressChange}
                          required
                          className="border border-gray-300 rounded-lg px-4 py-3 w-full
                          focus:outline-none focus:border-orange-500 focus:ring-1
                          focus:ring-orange-500 transition duration-300"
                        />
                      </div>
                      <div className=" flex justify-end gap-3 items-center px-4">
                        <button
                          onClick={() => setShowAddressInput(false)}
                          className="bg-red-500 px-6 py-2 w-[100px] text-white mt-4 rounded hover:bg-red-600"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={handleSubmitAddress}
                          className="bg-orange-400 px-6 py-2 text-white mt-4 w-[120px] rounded hover:bg-orange-600"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className=" right border rounded-lg p-4 md:w-[30%] w-[90%] h-full">
              <h3 className="text-xl font-semibold mb-4">Payment Summary</h3>
              <div className="flex justify-between mb-4">
                <span>Puja:</span>
                <span className="flex items-center gap-2">
                  <FaIndianRupeeSign /> {selectedCardDetails?.price}
                </span>
              </div>
              {selectedAddOns.length > 0 && (
                <div className="flex flex-col mb-4">
                  {dummyAddOnData
                    .filter((item) => selectedAddOns.includes(item.id))
                    .map((addOn) => (
                      <div key={addOn.id} className="flex justify-between">
                        <span>{addOn.title.slice(0, 23) + "..."}</span>
                        <span className="flex items-center gap-2">
                          <FaIndianRupeeSign /> {addOn.price}
                        </span>
                      </div>
                    ))}
                </div>
              )}
              <div className="mb-4 flex justify-between items-center">
                <span>Transaction Fee (2%):</span>
                <span className="flex items-center gap-2">
                  <FaIndianRupeeSign /> {transactionFee}
                </span>
              </div>
              <div className="mb-4 flex justify-between items-center">
                <span>Total:</span>
                <span className="flex items-center gap-2">
                  <FaIndianRupeeSign />{" "}
                  {Number(totalPrice) + Number(transactionFee)}
                </span>
              </div>
              <button
                disabled={isloading}
                onClick={() => handlePayment(gotraFocus)}
                className={`bg-orange-500 text-white w-full md:py-2 mt-4 md:rounded-lg flex justify-center items-center md:relative fixed bottom-0 left-0 py-4 ${isloading && "cursor-not-allowed"}`}
              >
                {isloading ? (
                  <Loader
                    color={"white"}
                    size={21}
                    speed={2}
                    stroke={3}
                    bgOpacity={""}
                  />
                ) : (
                  <>
                    Make Payment <FaIndianRupeeSign />{" "}
                    {Number(totalPrice) + Number(transactionFee)}
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReviewAndCheckOutEPooja;
