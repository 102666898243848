import React from "react";
import { FaYoutube } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa6";
import { NavLink } from "react-router-dom";

function Thankyou() {
  return (
    <div className="md:min-h-[80vh] min-h-screen w-full flex justify-center items-center flex-col gap-5 font-[inter]">
      <div className="text-center w-full flex justify-center flex-col items-center gap-3">
        <img
          src="https://cdn-icons-png.flaticon.com/512/4436/4436481.png"
          alt="Thankyou"
          className="h-[80px] w-[80px]"
        />
        <h1 className="font-bold text-4xl">Thank you!</h1>
        <p className=" w-full text-center mt-2">
          Thank you! for booking E-Pooja from{" "}
          <span className="font-semibold underline">Avadhoot</span>. Our team
          will reach you out shortly
        </p>
      </div>
      <div className=" w-full min-h-[200px] flex justify-center gap-6 flex-wrap p-6">
        <div className="bg-blue-100 w-full max-w-md flex justify-center items-center flex-col p-4 rounded-md">
          <h2 className="font-bold text-2xl text-center">Connect With Us</h2>
          <div className="flex justify-center items-center gap-3 mt-6">
            <a
              href="https://www.youtube.com/channel/UCTyk0Vi5T3x_NfxtRe213iQ"
              target="_blank"
              className="p-3 text-white bg-red-500 rounded-full hover:bg-transparent transition-all duration-300 ease-in-out hover:text-red-500"
            >
              <FaYoutube size={24} />
            </a>
            <a
              href="https://www.instagram.com/avadhoot_app/?hl=en"
              target="_blank"
              className="p-3 text-white bg-pink-500 rounded-full hover:bg-transparent transition-all duration-300 ease-in-out hover:text-pink-500"
            >
              <FaInstagram size={24} />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61564526307832"
              target="_blank"
              className="p-3 text-white bg-blue-500 rounded-full hover:bg-transparent transition-all duration-300 ease-in-out hover:text-blue-500"
            >
              <FaFacebookF size={24} />
            </a>
            <a
               href="https://api.whatsapp.com/send?phone=919201968470"
               target="_blank"
               className="p-3 text-white bg-green-500 rounded-full hover:bg-transparent transition-all duration-300 ease-in-out hover:text-green-500"
            >
              <FaWhatsapp size={24} />
            </a>
          </div>
        </div>
        <div className="bg-blue-100  flex justify-center items-center flex-col p-4 rounded-md w-full max-w-md">
          <h2 className="font-bold text-2xl text-center">Back To Home</h2>
          <a href="/"  className="mt-6 bg-orange-500 hover:bg-orange-600 px-10 py-3 rounded text-white">
            Go
          </a>
        </div>
      </div>
    </div>
  );
}

export default Thankyou;
