import axios from "axios";
import Loader from "components/Loader";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useNavigate } from "react-router-dom";
import { HOST, REGISTER_NEW_USER } from "utils/ApiRoutes";
import { Helmet } from "react-helmet";

const Register = () => {
  const navigate = useNavigate();
  const [newUser, setnewUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });
  const [isLoading, setisLoading] = useState(false);
  const handleClick = async (e) => {
    e.preventDefault();
    if (
      newUser.firstName.length < 3 ||
      newUser.lastName.length < 3 ||
      newUser.phoneNumber.length !== 12
    ) {
      toast.error("Enter a valid Name or Phone number");
      return;
    }
    try {
      setisLoading(true);
      const { data } = await axios.post(REGISTER_NEW_USER, { newUser });
      setisLoading(false);
      if (data?.status) {
        navigate("/login");
      }
    } catch (error) {
      if (error.response.data?.code === "P2002") {
        toast.error("User already exist with this phone number, Please login");
        setisLoading(false);
        return;
      } else {
        console.log(error);
      }
    }
  };
  return (
    <>
      <Helmet>
         <title>Avadhoot Register Page</title>
         <link rel="canonical" href={`${HOST}/register`}/>
         <meta name="description" content="Sign up for a free account at Avadhoot Journey and unlock exclusive features. " />
      </Helmet>
      <div>
        <div>
          <Toaster />
        </div>
        <div className="flex flex-col items-center justify-center py-14">
          <h1 className="font-bold text-4xl">Welcome to Avadhoot</h1>
          <div className="rounded-md mt-5 px-10 pb-6 max-w-[90%] w-[400px] bg-gray-200 border border-transparent">
            <h3 className="font-bold text-2xl my-3 py-4">Create an account</h3>
            <form action="">
              <div class="mb-5">
                <label
                  for="firstName"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  First Name
                </label>
                <input
                  onChange={(e) =>
                    setnewUser({ ...newUser, firstName: e.target.value })
                  }
                  value={newUser.firstName}
                  type="text"
                  minLength={3}
                  id="firstName"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="First Name"
                  required
                />
              </div>
              <div class="mb-5">
                <label
                  for="lastName"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Last Name
                </label>
                <input
                  minLength={3}
                  onChange={(e) =>
                    setnewUser({ ...newUser, lastName: e.target.value })
                  }
                  value={newUser.lastName}
                  type="text"
                  id="lastName"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Last Name"
                  required
                />
              </div>
              <div class="mb-5">
                <label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your email
                </label>
                <input
                  onChange={(e) =>
                    setnewUser({ ...newUser, email: e.target.value })
                  }
                  value={newUser.email}
                  type="email"
                  id="email"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="name@example.com"
                  required
                />
              </div>
              <div class="mb-5">
                <label
                  for="phoneNumber"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Phone Number
                </label>
                <PhoneInput
                  onChange={(e) => setnewUser({ ...newUser, phoneNumber: e })}
                  value={newUser.phoneNumber}
                  country={"in"}
                  type="number"
                  id="phoneNumber"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
              <p className="text-xs py-2">
                By clicking Sign Up, you agree to our Terms, Privacy Policy and
                Cookies Policy. You may receive SMS notifications from us and
                can opt out at any time.
              </p>
              <button
                onClick={(e) => handleClick(e)}
                type="submit"
                class="text-white text-lg bg-orange-500 hover:bg-orange-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                {isLoading ? (
                  <Loader
                    color={"white"}
                    size={21}
                    speed={2.0}
                    stroke={3}
                    bgOpacity={""}
                  ></Loader>
                ) : (
                  "Create account"
                )}
              </button>
              <h5 className="text-center pt-5">
                Alredy have an account?{" "}
                <a className="text-purple-700" href="/login">
                  Login
                </a>
              </h5>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
