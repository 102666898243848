import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MdOutlineTempleHindu } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import { FaAngleRight } from "react-icons/fa6";
import Footer from "components/Footer";
import { TbBrandWhatsapp } from "react-icons/tb";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaWhatsapp } from "react-icons/fa6";
import { MdOutlineMail } from "react-icons/md";
import toast, { Toaster } from "react-hot-toast";
import { CiUser } from "react-icons/ci";
import {
  EpoojaImages,
  epoojaTempleImages,
} from "assets/images/Epooja/EpoojaImages";
import { Epooja } from "Epooja";
import { trackButtonClicks } from "Analytics/GoogleAnalytics";
import axios from "axios";
import { SEND_EPUJA_USER_DATA } from "utils/ApiRoutes";
import Loader from "components/Loader";
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1324 },
    items: 4,
    slidesToSlide: 2,
  },
  desktop: {
    breakpoint: { max: 1324, min: 1000 },
    items: 3,
    slidesToSlide: 2,
  },
  tablet: {
    breakpoint: { max: 1000, min: 664 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 664, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const cards = [
  {
    title: "Single",
    content: "1 person",
    price: "851",
    people: 1,
    img: "https://c8.alamy.com/comp/W6XM01/south-indian-man-holding-puja-thali-W6XM01.jpg",
  },
  {
    title: "Couple",
    content: "Up to 2 people",
    price: "1301",
    people: 2,
    img: "https://www.shutterstock.com/image-photo/smart-indian-couple-performing-sai-260nw-2248455745.jpg",
  },
  {
    title: "Family",
    content: "Up to 4 people",
    price: "1901",
    people: 4,
    img: "https://c8.alamy.com/comp/D0MCWB/family-doing-diwali-pooja-D0MCWB.jpg",
  },
];

function EPooja2Page() {
  const parseObjs = (faqs) => {
    const arr = Object.keys(faqs).map((val, i) => {
      return {
        title: val,
        content: faqs[val],
      };
    });
    return arr;
  };

  const urlTitle = useParams();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [currentIndex, setCurrentIndex] = useState(0);
  const location = useLocation();
  const filterData =
    Epooja && Epooja.filter((item, i) => item.title === urlTitle.title);
  const receivedData = filterData[0];
  const {
    id,
    title,
    short_desc,
    place,
    date,
    customer_review,
    about_puja,
    faqs,
    benefit_of_puja,
    about_temple,
    price,
  } = receivedData;
  const aboutPooja = about_puja;
  const [activeIndex, setActiveIndex] = useState(1);
  const [activeList, setActiveList] = useState(null);
  const [faqList, setFaqList] = useState(null);
  const [readMorePooja, setReadMorePooja] = useState(false);
  const [readMoreTemple, setReadMoreTemple] = useState(false);
  const navigate = useNavigate();
  const scrollDownCards = useRef(null);
  const scrollDownForm = useRef(null);
  const [showFormInputs, setShowFormInputs] = useState(false);
  const [inputFields, setInputFields] = useState([]);
  const [usersName, setUsersName] = useState([]);
  const [selectedCardDetails, setSelectedCardDetails] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [userEmail, setUserEmail] = useState(
    user?.firstName ? "john@example.com" : ""
  );
  const [poojaFaqs, setPoojaFaqs] = useState(parseObjs(faqs));
  const [items, setitems] = useState(parseObjs(benefit_of_puja));
  const [isLoading, setisLoading] = useState(false);
  const images = [
    EpoojaImages[id],
    ...epoojaTempleImages[id],
    "https://images.pexels.com/photos/3810915/pexels-photo-3810915.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/1707422/pexels-photo-1707422.jpeg?auto=compress&cs=tinysrgb&w=600",
  ];
  const scrollButtonToCards = (sectionRef) => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollCardsToForm = (sectionRef) => {
    sectionRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  };
  const notify = () => {
    toast.error(`Please select a Pooja Card.`);
  };
  const handleReadAboutPooja = () => {
    setReadMorePooja(!readMorePooja);
  };
  const handleReadMoreTemple = () => {
    setReadMoreTemple(!readMoreTemple);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  const handleImageClick = (index) => {
    setCurrentIndex(index);
  };

  const handleClick = (index) => {
    setActiveList(activeList === index ? null : index);
  };
  const handleClickFaqs = (index) => {
    setFaqList(faqList === index ? null : index);
  };

  const handleCardClick = (index) => {
    setActiveIndex(index);
    const selectedCard = cards[index];
    // console.log(selectedCard.people)
    setSelectedCardDetails({ ...selectedCard, price: price[index + 1] });
    setShowFormInputs(true);
    const newInputFields = Array.from(
      { length: selectedCard.people },
      (_, i) => ({
        value: "",
        id: i + 1,
      })
    );

    setInputFields(newInputFields);
    setUsersName(Array(selectedCard.people).fill(""));
  };

  const handleInputChange = (index, event) => {
    const values = [...usersName];
    values[index] = event.target.value;
    setUsersName(values);
  };

  const handleSubmitUserNameAndDetails = async () => {
    setisLoading(true);
    console.log({ selectedCardDetails, usersName, mobileNumber });
    trackButtonClicks(
      "Proceed_to_checkout_btn",
      "custom_event",
      "To chkout E-pooja"
    );
    try {
      await axios.post(SEND_EPUJA_USER_DATA, { mobileNumber, usersName });
    } catch (error) {
      console.log(error);
      
    }
    navigate(`/e-pooja/checkOut/${receivedData.title}`, {
      state: {
        receivedData,
        selectedCardDetails,
        usersName,
        mobileNumber,
        userEmail: user ? user?.email : userEmail,
        img: EpoojaImages[id],
      },
    });
  };

  const handleBookPooja = (receivedData) => {
    const selectedCard = cards[activeIndex];
    navigate(`/e-pooja/checkOut/${receivedData.title}`, {
      state: { receivedData, selectedCard },
    });
  };

  const handleShare = () => {
    toast.success("Link copied to clipboard");
    const url = "https://avadhootapp.com/e-pooja/";
    navigator.clipboard.writeText(window.location.href);
    setTimeout(() => {
      window.location.href = `https://api.whatsapp.com/send/?text=${
        url + receivedData?.title
      }`;
    }, 1000);
  };

  return (
    <>
      <Toaster />
      <div className="flex font-[inter] flex-col items-center md:items-start justify-center space-y-4 pb-4 md:flex-row md:space-y-0 md:space-x-6 md:p-8 ">
        <div className="relative w-full max-w-lg md:w-1/2">
          <img
            src={images[currentIndex]}
            alt={`Puja ${currentIndex}`}
            className="w-full h-64 object-cover md:rounded-lg md:h-80 lg:h-96"
          />
          <div className="flex mt-4 space-x-2 justify-evenly object-cover px-4">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Thumbnail ${index}`}
                onClick={() => handleImageClick(index)}
                className={`w-16 h-16 object-cover cursor-pointer rounded-lg ${
                  index === currentIndex
                    ? "border-2 border-orange-400"
                    : "border"
                }`}
              />
            ))}
          </div>
        </div>
        <div className="md:w-1/2 w-[100%] md:text-left h-64  md:h-80 lg:h-96 py-4 px-5 md:px-2 flex flex-col justify-evenly">
          <h2 className="text-2xl font-semibold md:text-3xl">{title}</h2>
          <p className="text-sm text-gray-500 md:text-lg"> {short_desc}</p>
          <p className="text-sm text-gray-500 md:text-lg flex gap-2 items-center">
            <MdOutlineTempleHindu />
            {place}
          </p>
          <p className="text-sm text-gray-500 md:text-lg flex gap-2 items-center">
            <SlCalender />
            {date.toDateString()}
          </p>
          <p className="font-semibold text-sm ">
            38 people have already booked this pooja
          </p>
          <p
            onClick={handleShare}
            className="cursor-pointer flex gap-2 text-[12px] items-center"
          >
            <TbBrandWhatsapp size={24} className="text-green-600" /> Share{" "}
          </p>
          <button
            onClick={() => {
              trackButtonClicks(
                "Book Pooja Btn",
                "custom_event",
                "To Book E-pooja in page 2"
              );
              scrollButtonToCards(scrollDownCards);
              notify();
            }}
            className={`px-4 md:relative fixed bottom-0 left-0 py-4  md:mt-2 border border-orange-400 md:rounded-lg z-20 w-full bg-orange-400 text-white transition-all hover:bg-orange-500 md:px-6 md:py-3 ${
              showFormInputs && "hidden md:inline-block"
            }`}
          >
            Book Pooja
          </button>
        </div>
      </div>
      {/* packages */}
      <div
        ref={scrollDownCards}
        className="packages font-[inter] w-full py-2 px-4 md:px-12"
      >
        <h3 className="text-lg text-center md:text-3xl mt-2 font-semibold">
          Pooja Packages
        </h3>
        {/* <p className="mt-2 text-[#60606ae6]">
          <span className="font-semibold">Special Offer</span>: Enjoy
          complimentary Prasad with every package, plus receive a discount card
          for your next pooja booking.
        </p> */}
        <div className="relative flex flex-col  md:flex-row justify-center md:gap-10 gap-2 items-center min-h-[60vh] w-full">
          {cards.map((card, index) => {
            const isActive = index === activeIndex;
            return (
              <div
                key={index}
                onClick={() => {
                  handleCardClick(index);
                  setActiveIndex(index);
                  scrollCardsToForm(scrollDownForm);
                }}
                className={`flex flex-col items-center rounded-lg overflow-hidden m-4 transition-all duration-500 ease-in-out ${
                  isActive
                    ? "scale-100 opacity-100 z-10"
                    : "scale-90 opacity-70"
                } bg-white shadow-lg cursor-pointer`}
                style={{
                  height: isActive ? "20rem" : "14rem",
                  width: isActive ? "230px" : "150px",
                  boxShadow: isActive ? "0 0 5px 0" : "0 0 3px 0",
                }}
              >
                <img
                  src={card.img}
                  alt=""
                  className="object-cover h-full w-full"
                />
                <div
                  className={`absolute bottom-0 left-0 w-full bg-gradient-to-r from-orange-300 to-orange-500 text-white text-center transition-all duration-500 ease-in-out`}
                >
                  <h2 className="text-lg font-bold mb-0">{card.title}</h2>
                  <p>₹ {price[index + 1]}</p>
                  <p>{card.content}</p>
                </div>
              </div>
            );
          })}
        </div>
        <div ref={scrollDownForm}>
          {showFormInputs && (
            <div className="bg-white md:h-auto h-[80vh] w-full mt-6 p-6 relative rounded-lg shadow-lg md:overflow-hidden overflow-x-hidden overflow-y-scroll">
              <div className="relative w-full mb-6 border-b-2 py-4 pb-8">
                <div className="mb-4 relative">
                  <h3 className="font-semibold md:text-lg">
                    Your WhatsApp Number and E-mail
                  </h3>
                  <p className="md:text-[13px] text-[11px] text-gray-500 ">
                    Your Pooja booking updates like Pooja Photos, Videos and
                    other details will be sent on WhatsApp on below number and
                    E-mail.
                  </p>
                </div>
                <div className="relative">
                  <p className="absolute flex gap-2 top-[50%] items-center ml-2 transform -translate-y-1/2 text-green-500">
                    <FaWhatsapp size={18} className="mb-1" />
                    <span className="text-black mr-5">+91 </span>
                  </p>
                  <input
                    type="text"
                    placeholder="WhatsApp number"
                    value={mobileNumber}
                    minLength={10}
                    maxLength={10}
                    id={`WhatsApp Number`}
                    required
                    onChange={(e) => setMobileNumber(e.target.value)}
                    className="border border-gray-300 rounded-lg pl-16  pr-4 py-3 w-full focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500 transition duration-300"
                  />
                </div>
                {!user && (
                  <div className="relative mt-3">
                    <p className="absolute flex gap-2 top-[50%] items-center ml-2 transform -translate-y-1/2 text-red-500">
                      <MdOutlineMail size={20} className="mb-1 mt-[5px]" />
                    </p>
                    <input
                      type="text"
                      placeholder="Email Address"
                      value={userEmail}
                      minLength={10}
                      id={`Email Address`}
                      required
                      onChange={(e) => setUserEmail(e.target.value)}
                      className="border border-gray-300 rounded-lg pl-9  pr-4 py-3 w-full focus:outline-none focus:border-red-500 focus:ring-1 focus:ring-red-500 transition duration-300"
                    />
                  </div>
                )}
              </div>

              <div className="mb-4">
                <h3 className="font-semibold md:text-lg">
                  Name of members participating in Puja
                </h3>
                <p className="md:text-[13px] text-gray-500 text-[11px]">
                  Our Panditji will take these names along with gotra during the
                  puja.
                </p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6  pb-8">
                {inputFields.map((_, i) => (
                  <div key={i} className="relative">
                    <label className="text-gray-600 ml-1" htmlFor="">
                      Participant {i + 1}{" "}
                      <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder={`Full Name`}
                      value={usersName[i] || ""}
                      required
                      onChange={(e) => handleInputChange(i, e)}
                      className="border border-gray-300 rounded-lg px-4 py-3 w-full focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition duration-300"
                    />
                  </div>
                ))}
              </div>
              <button
                onClick={handleSubmitUserNameAndDetails}
                disabled={
                  mobileNumber.length !== 10 ||
                  usersName.some((name) => !name) ||
                  !userEmail.length ||
                  !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                    userEmail
                  )
                }
                className={`fixed bottom-0 z-20 md:absolute md:bottom-4 md:right-6 md:left-6 left-0 right-6  md:w-[20%] w-[100%]  py-3 md:rounded-lg text-white transition duration-300 ${
                  mobileNumber.length === 10 &&
                  usersName.every((name) => name) &&
                  userEmail.length &&
                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                    userEmail
                  )
                    ? " bg-orange-400 hover:bg-orange-600 "
                    : "bg-gray-400 cursor-not-allowed"
                }`}
              >
                {isLoading ? (
                  <Loader
                    size={19}
                    speed={2}
                    stroke={3}
                    color={"white"}
                    bgOpacity={""}
                  />
                ) : (
                  "PROCEED TO CHECKOUT"
                )}
              </button>
              <button
                onClick={() => setShowFormInputs(false)}
                className="text-white rounded-lg md:py-3 py-2 px-3 ml-1 md:px-0 md:w-[8%] bg-red-600 hover:bg-red-600 md:absolute md:left-[23%] md:bottom-4"
              >
                Cancel
              </button>
            </div>
          )}
        </div>

        {/* Pooja Details */}
        <div className="poojaDetails mt-6 w-[100%]">
          <h3 className="md:text-2xl text-lg mb-8  border-b">Pooja Details</h3>
          <h3 className="md:text-xl text-[12px] mt-2 mb-4 text-orange-500 w-[200px] border-b-2 border-orange-500">
            Benefits of Pooja
          </h3>
          <div className="mx-auto w-[100%] ">
            {items.map((item, index) => (
              <div
                key={index}
                className={`mb-4 border-b w-[100%] border-gray-300 `}
              >
                <button
                  onClick={() => handleClick(index)}
                  className="w-full text-left py-4 flex justify-between items-center focus:outline-none"
                >
                  <span
                    className={`text-lg md:px-4 px-2 font-semibold ${
                      activeList === index
                        ? "border-l-4 rounded-xl border-orange-500 text-orange-400"
                        : "border-l border-orange-500"
                    } transition-all duration-300 ease-in-out`}
                  >
                    {item.title}
                  </span>
                  <span
                    className={`transition-all duration-300 ease-in-out  ${
                      activeList === index ? "rotate-90" : "rotate-0"
                    } }`}
                  >
                    <FaAngleRight />
                  </span>
                </button>
                <div
                  className={`overflow-hidden transition-all duration-500 ${
                    activeList === index ? "max-h-96" : "max-h-0"
                  }`}
                >
                  <div className="py-2 px-2 md:text-[14px] text-[#4a4a56c6]">
                    {item.content}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* About Pooja */}
          <div className="benefits mt-10 h-full text-black">
            <h3 className="md:text-xl text-[12px] mt-2 mb-4 text-orange-500 w-[150px] border-b-2 border-orange-500">
              About Pooja
            </h3>
            <div className="flex md:justify-start justify-center items-center md:flex-row flex-col gap-4 w-[100%] ">
              <img
                className="object-cover h-[16rem] md:w-[30%] w-[80%] rounded-lg "
                src={EpoojaImages[id]}
                alt="About pooja image"
              />
              <p className="text-[black] transition-all duration-200 ease-in-out h-[100%] md:w-[60%] w-[90%]">
                {readMorePooja ? aboutPooja : aboutPooja.slice(0, 300)}
                <span
                  onClick={handleReadAboutPooja}
                  className="underline border-b cursor-pointer text-blue-400"
                >
                  {readMorePooja ? " read less" : " ...read more"}
                </span>
              </p>
            </div>
          </div>

          {/* About temple */}

          <div className="benefits mt-10 h-full text-black">
            <h3 className="md:text-xl text-[12px] mt-2 mb-4 text-orange-500 w-[150px] border-b-2 border-orange-500">
              About temple
            </h3>
            <div className="flex md:justify-start justify-center items-center md:flex-row flex-col gap-4 w-[100%] ">
              <img
                className="object-cover h-[16rem] md:w-[30%] w-[80%] rounded-lg "
                src={images[1]}
                alt="About pooja image"
              />
              <p className="text-[black] transition-all duration-200 ease-in-out h-[100%] md:w-[60%] w-[90%]">
                {" "}
                {readMoreTemple ? about_temple : about_temple.slice(0, 300)}
                <span
                  onClick={handleReadMoreTemple}
                  className="underline border-b cursor-pointer text-blue-400"
                >
                  {readMoreTemple ? " read less" : " ...read more"}
                </span>
              </p>
            </div>
          </div>
        </div>

        {/* Faqs */}
        <div className="benefits mt-10 h-full text-black ">
          <h3 className="md:text-2xl text-[14px] mt-2 mb-4   font-bold">
            FAQs
          </h3>
          <div className="mx-auto w-[100%] ">
            {poojaFaqs.map((item, index) => (
              <div
                key={index}
                className={`mb-4 border-b w-[100%] border-gray-300 `}
              >
                <button
                  onClick={() => handleClickFaqs(index)}
                  className="w-full text-left py-4 flex justify-between items-center focus:outline-none"
                >
                  <span
                    className={`text-lg md:px-4 px-2 font-semibold ${
                      faqList === index
                        ? "border-l-4 rounded-xl border-orange-500 text-orange-400"
                        : "border-l border-orange-500"
                    } transition-all duration-300 ease-in-out`}
                  >
                    {item.title}
                  </span>
                  <span
                    className={`transition-all duration-300 ease-in-out  ${
                      faqList === index ? "rotate-90" : "rotate-0"
                    } }`}
                  >
                    <FaAngleRight />
                  </span>
                </button>
                <div
                  className={`overflow-hidden transition-all duration-500 ${
                    faqList === index ? "max-h-96" : "max-h-0"
                  }`}
                >
                  <div className="py-2 px-2 md:text-[14px] text-[#4a4a56c6]">
                    {item.content}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="costumerReviews py-6 px-4 md:px-12 min-h-[40vh] flex justify-center flex-col">
        <h3 className="font-bold text-3xl md: ml-4">Customer Reviews</h3>

        <Carousel
          transitionDuration={500}
          infinite={true}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          autoPlay
          className=" h-[100%] py-8"
          responsive={responsive}
        >
          {customer_review.map((review, i) => (
            <div
              key={i}
              className="flex-shrink-0 ml-3 mr-3   max-w-md p-4 bg-white shadow-xl rounded-lg mx-2"
            >
              <div className="flex gap-2 items-end ">
                <CiUser className="text-2xl mb-1" />
                {/* <img
                  src={review.img}
                  alt={review.name}
                  className="rounded-full h-[2rem] w-[2rem] gap-2 object-cover"
                /> */}
                <h3 className="text-[14px] font-semibold mb-2">
                  {review.name}
                </h3>
              </div>
              <p className="text-sm mb-2">{review.review}</p>
              <div className="flex items-center">
                {Array.from({ length: review.rating }).map((_, index) => (
                  <svg
                    key={index}
                    className="w-4 h-4 text-yellow-500 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 2l2.39 4.81 5.33.77-3.87 3.77.91 5.3-4.77-2.51-4.77 2.51.91-5.3L4.28 8.58 9.61 7.81 12 2z" />
                  </svg>
                ))}
              </div>
            </div>
          ))}
        </Carousel>
      </div>
      <Footer />
    </>
  );
}

export default EPooja2Page;
