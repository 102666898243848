import React from "react";

const CustomModal = ({ModalContent, visibleModel}) => {
  return (
    <div
      className={`fixed top-0 left-0 w-full h-full p-4 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-500 ease-out z-50 ${
        visibleModel ? "opacity-100" : "opacity-0"
      }`}
    >
      <div
        className={`relative p-6 rounded-lg overflow-hidden shadow-lg max-w-sm w-full bg-white transition-transform duration-500 ease-out transform ${
          visibleModel ? "scale-100" : "scale-90"
        }`}
      >
        {ModalContent}
      </div>
    </div>
  );
};

export default CustomModal;
