import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import GuestControl from "./GuestControl";
import { reducerCases } from "context/constants";
import { useStateProvider } from "context/StateContext";


export default function RoomsAndGuests({handleApply}) {
    const [rooms, setRooms] = useState(1);
    const [guests, setGuests] = useState(2);
    const [{premiumPackage},dispatch] = useStateProvider()

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  

  return (
    <div className="border border-gray-400 rounded-md md:h-[55.5px] text-gray-300 md:wd-[60%]">
      <Button
        sx={{color:"grey", border:"none", paddingX:"20px", width:"100%"}}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <div className="">
          <h3 className="capitalize text-xs">Rooms & Guests</h3>
          <h3 className="text-lg">{guests} GUESTS</h3>
        </div>
      </Button>
      <Menu
        className="md:w-[50%]"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div className="flex flex-col items-center justify-center">

        <GuestControl sec="Adults above 12 years" value={guests} setValue={setGuests} val2={rooms} setVal2={setRooms} val={"GUESTS"}/>
        {/* <GuestControl value={rooms} setValue={setRooms} val={"ROOMS"}/> */}
        <div className=" capitalize text-lg bg-red-400 p-1 rounded-md text-white my-1 mr-3">Required Rooms : {rooms}</div>
        <h3 className="text-red-400 px-4">Maximum 3 guests are allowed in this room</h3>
        <Button onClick={()=>{handleClose();handleApply(guests,rooms)}} sx={{marginY:"10px"}} variant="contained" color="primary">Apply</Button>
        </div>
        
      </Menu>
    </div>
  );
}
