import ControlButton from "components/ControlButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { getFullDate } from "utils/HelperFuncs";
import dayjs from "dayjs";
import { useStateProvider } from "context/StateContext";
import { reducerCases } from "context/constants";
import { useLocation, useNavigate } from "react-router-dom";
import poojaLogo from "assets/icons/pooja.png";
import { CiWarning } from "react-icons/ci";
import {FullPoojaImages} from "assets/images/Poojas/PoojaImages";
import { trackButtonClicks } from "Analytics/GoogleAnalytics";
import { FaRegStar } from "react-icons/fa";
import toast, { Toaster } from "react-hot-toast";


const PoojaBooking = () => {
  const today = getFullDate();
  const [date, setdate] = useState(today);
  const [yajmans, setYajmans] = useState("0");
  const [showMore, setShowMore] = useState(true);
  let currentPooja = JSON.parse(localStorage.getItem("currentPooja"));
  let [{}, dispatch] = useStateProvider();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleChange = (e) => {
    let bookingDate = dayjs(`${e.$y}-${e.$M + 1}-${e.$D}`);
    setdate(bookingDate);
  };

  const handleBookingClick = (e) => {
    trackButtonClicks(
      "Pooja_Booking_payment_btn",
      "custom_event",
      `To payment for ${currentPooja.title}`
    );
    e.preventDefault();
    const { $y: year, $M: month, $D: day } = date;

    if (parseInt(yajmans) > 0) {
      let s_arr = currentPooja.price.split(" ");
      let toPay = parseInt(s_arr[s_arr.length - 1]);
      sessionStorage.setItem("currOrder", JSON.stringify(
        {
          date:`${day}-${month+1}-${year}`,
          people: yajmans,
          category: "Pooja",
          title: currentPooja.title,
          subTotal: parseInt(yajmans) * toPay,
          orderObj:currentPooja
        }
      ))

      dispatch({
        type: reducerCases.SET_CURRENT_ORDER,
        order: {
          date:`${day}-${month+1}-${year}`,
          people: yajmans,
          category: "Pooja",
          title: currentPooja.title,
          subTotal: parseInt(yajmans) * toPay,
          orderObj:currentPooja
        },
      });
      dispatch({
        type: reducerCases.SET_PREV_PAGE,
        prevPage: location.pathname,
      });
      navigate("/order-summary");
    } else {
      toast.error("Need 1 or more Yajmans for Pooja");
      return;
    }
  };

  // console.log(currentPooja)

  // console.log(showMore)

  return (
    <div>
      <div><Toaster/></div>
      {/* Pooja Content */}
      <div className="mb-5 pb-4 font-[inter]">
        <div className="my-4 px-4 md:ml-[5%] py-4 max-w-[1400px] flex flex-col md:flex-row  md:gap-10 items-center justify-center">
          <div className="mb-5 rounded-lg  w-full max-w-xl max-h-lg  h-full ">
            <img
              className="rounded-lg shadow-xl w-full h-full  max-h-[350px]  object-cover"
              src={
                FullPoojaImages[currentPooja.id]
                  ? FullPoojaImages[currentPooja.id]
                  : poojaLogo
              }
              alt={currentPooja.title}
            />
          </div>
          <div className="w-full max-w-xl ">
            <div className="mb-3">
              <h2 className="text-start text-[22px] md:text-3xl font-bold mb-2">
                {currentPooja?.title}
              </h2>
              <p className="text-[13px] text-gray-500">
                {showMore && (
                  <>
                    {currentPooja?.long_desc.slice(0, 110)}
                    {currentPooja?.long_desc?.length > 110 && (
                      <span
                        className="underline text-blue-500 hover:text-blue-700 cursor-pointer"
                        onClick={() => setShowMore(false)}
                      >
                        show more
                      </span>
                    )}
                  </>
                )}
                {!showMore && (
                  <>
                    {currentPooja?.long_desc}
                    <span
                      className="underline text-blue-500 hover:text-blue-700 cursor-pointer"
                      onClick={() => setShowMore(true)}
                    >
                      show less
                    </span>
                  </>
                )}
              </p>
            </div>
            <div className="rounded-lg px-4 py-2  bg-white shadow-md border border-gray-200">
              <form className="">
                <div className="flex flex-col space-y-5 items-center justify-center">
                  <div className="w-full px-2">
                    <ControlButton yajmans={yajmans} setYajmans={setYajmans} />
                  </div>
                  <div className="w-full">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={date}
                        onChange={(e) => handleChange(e)}
                        disablePast
                        minDate={today}
                        className="w-full rounded-lg"
                        label="Choose Date"
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="flex w-full justify-center mt-6">
                  <button
                    onClick={(e) => handleBookingClick(e)}
                    className="bg-orange-500 hover:bg-orange-600 w-full  px-6  text-white rounded-lg py-2  transition duration-300"
                  >
                    Book Pooja
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="md:max-w-6xl max-w-3xl md:mx-auto mx-4 my-8 p-8 border border-orange-500 shadow-xl rounded-lg bg-gradient-to-r from-yellow-100 via-orange-50 to-orange-100">
          <div className="mb-6">
            <h3 className="text-2xl font-bold text-orange-600 mb-4">
              Benefits of this Pooja
            </h3>
            <ul className="space-y-4">
              {Object.keys(currentPooja?.benefit_of_puja).map((p_key) => (
                <li key={p_key} className="text-lg font-semibold text-gray-800">
                  <p className="flex md:items-center  gap-2"> <span className="text-orange-600 md:mt-0 mt-1"><FaRegStar /></span>   {p_key} -</p>
                  <p className="text-sm font-normal text-gray-600 mt-1">
                     {currentPooja.benefit_of_puja[p_key]}
                  </p>
                </li>
              ))}
            </ul>
          </div>
          <hr className="border-orange-300 my-5" />

          <div className="mb-6">
            <h3 className="text-xl font-bold text-orange-600">
              Place of Pooja
            </h3>
            <h4 className="text-lg font-semibold text-gray-800 mt-2">
              <p className="flex md:items-center gap-2"><span className="text-orange-600 md:mt-0 mt-1"><FaRegStar /></span>
              {currentPooja.place?.length === 0 ? "Ujjain" : currentPooja.place}
              </p>
           </h4>
          </div>
          <hr className="border mx-10 my-5"/>
          <div className="mb-3">
            <h3 className="title">FAQs</h3>
            <ul className="mt-2">
              {Object.keys(currentPooja?.faq).map((f_key) => {
                return (
                  <li
                    key={f_key}
                    className="text-xl font-semibold text-gray-700 leading-[20px]"
                  >
                    {f_key}{" "}
                    <p className="text-sm pl-5 pb-2 font-normal">
                      {currentPooja.faq[f_key]}
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoojaBooking;
