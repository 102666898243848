import React from "react";
import { FaInstagram, FaTwitter, FaFacebookF , FaYoutube } from "react-icons/fa";
import image2 from "assets/images/startUpLogo.png"
import image3 from "assets/images/DPIITLogo.png"

const Footer = () => {
  return (
    <footer className="bg-gradient-to-t mt-10 from-gray-200 via-gray-300 to-gray-100 dark:from-gray-900 dark:to-gray-800 text-gray-700 dark:text-gray-300 font-[inter]">
      <div className="mx-auto w-full max-w-screen-xl py-8 px-4">
        <h3 className="text-center font-bold text-lg mb-5 text-gray-700 dark:text-gray-100">
          Follow us on:
        </h3>

        <div className="flex justify-center space-x-8 mb-8">
          <a
            href="https://www.facebook.com/profile.php?id=61564526307832" target="_blank"
            className="text-gray-500 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-400 transition-colors duration-300 transform hover:scale-125"
          >
            <FaFacebookF size={24} />
          </a>
          <a
            href="https://www.instagram.com/avadhoot_app/?hl=en" target="_blank"
            className="text-gray-500 dark:text-gray-400 hover:text-pink-500 dark:hover:text-pink-400 transition-colors duration-300 transform hover:scale-125"
          >
            <FaInstagram size={24} />
          </a>
          <a
            href="https://www.youtube.com/channel/UCTyk0Vi5T3x_NfxtRe213iQ" target="_blank"
            className="text-gray-500 dark:text-gray-400 hover:text-red-400 dark:hover:text-red-500 transition-colors duration-300 transform hover:scale-125"
          >
            <FaYoutube size={24} />
          </a>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <div className="text-center">
            <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-4">Company</h4>
            <ul className="space-y-2">
              <li><a href="#" className="hover:underline">About</a></li>
              <li><a href="#" className="hover:underline">Blog</a></li>
        <li>  <a
            href="https://www.facebook.com/profile.php?id=61564526307832" target="_blank"
          className="hover:underline"
          >
             Facebook
           </a></li>
           <li>
          <a
            href="https://www.instagram.com/avadhoot_app/?hl=en" target="_blank"
          className="hover:underline"
          >
            instagram
          </a>
          </li>
          <li>
          <a
            href="https://www.youtube.com/channel/UCTyk0Vi5T3x_NfxtRe213iQ" target="_blank"
          className="hover:underline"
          >
             Youtube
          </a>
          </li>
            </ul>
          </div>

          <div className="text-center">
            <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-4">Legal</h4>
            <ul className="space-y-2">
              <li><a href="/privacy_policy.html" target="_blank" rel="noopener noreferrer" className="hover:underline">Privacy Policy</a></li>
              <li><a href="/shipping_policy.html" target="_blank" rel="noopener noreferrer" className="hover:underline">Shipping Policy</a></li>
              <li><a href="/refund_policy.html" target="_blank" rel="noopener noreferrer" className="hover:underline">Refund Policy</a></li>
              <li><a href="/terms_conditions.html" target="_blank" rel="noopener noreferrer" className="hover:underline">Terms & Conditions</a></li>
            </ul>
          </div>

          <div className="text-center">
            <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-4">Download</h4>
            <ul className="space-y-2">
              <li><a href="/comingSoon" className="hover:underline">iOS</a></li>
              <li><a href="/comingSoon" className="hover:underline">Android</a></li>
            </ul>
          </div>
        </div>

       <div className="h-[20vh] w-full  flex justify-center items-center flex-col border-t border-gray-300 dark:border-gray-700 py-4 mb-4" >
                <h1 className="px-8 py-2 font-semibold text-xl">RECOGNIZED BY</h1>
                <div className="h-full flex justify-center gap-4 items-center mt-4 ">
                    <img src={image2} alt=""  className="md:h-[50px] md:w-[260px] w-[130px] object-cover"/>
                    <img src={image3} alt=""  className="md:h-[50px] md:w-[200px] w-[130px] object-cover mb-3"/>
                </div>
        </div>  
      
        <div className="text-center py-4 border-t border-gray-300 dark:border-gray-700">
          <p className="text-sm">
            © 2024 <a href="/" className="font-semibold hover:underline">Avadhoot Journey Private Limited ™</a>. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
