import React, { useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { RiSubtractFill } from "react-icons/ri";

const GuestControl = ({ val, value, setValue, sec = "", val2, setVal2 }) => {
  const handleChange = () => {};
  const handleDec = () => {
    if (value > 1) {
      setValue(value - 1);
      let newVal = parseInt(value) - 1;
      setVal2(prev=>{
        let x = parseInt(parseInt(newVal) / 3)
        if((parseInt(newVal) % 3) !==0){
          return x+1
        }
        return x
      });
    }
  };
  const handleInc = () => {
    if (value === "") {
      setValue(1);
    } else {
      if (val === "GUESTS") {
        if (parseInt(value) >= 3) {
          setValue(parseInt(value) + 1);
          let newVal = parseInt(value) + 1;
          setVal2(prev=>{
            let x = parseInt(parseInt(newVal) / 3)
            if((parseInt(newVal) % 3) !==0){
              return x+1
            }
            return x
          });
        } else {
          setValue(parseInt(value) + 1);
        }
      } else {
        setValue(parseInt(value) + 1);
      }
    }
  };
  return (
    <div className="px-10 py-2">
      <label
        htmlFor="quantity-input"
        className="mb-2 mx-5 pt-2 text-gray-900 dark:text-gray-800"
      >
        ADD {val} :
      </label>
      <div className="text-xs text-center">{sec}</div>
      <div className="relative flex items-center max-w-[8rem] py-1">
        <button
          onClick={(e) => handleDec(e)}
          type="button"
          className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:outline-none"
        >
          <RiSubtractFill />
        </button>
        <input
          onChange={(e) => handleChange(e)}
          value={value}
          type="text"
          id="quantity-input"
          data-input-counter
          aria-describedby="helper-text-explanation"
          className="bg-gray-50 border-x-0 border-y border-gray-300 h-11 text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="1"
          required
        />
        <button
          onClick={(e) => handleInc(e)}
          type="button"
          className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:outline-none"
        >
          <IoMdAdd />
        </button>
      </div>
    </div>
  );
};

export default GuestControl;
