import kaalSarp from './KaalSarp.jpg';
import grahShanti from './grahShanti.jpg'
import chandra from './chandraShanti.jpg'
import mabagla from './maa_baglamukji_jaap_havan.jpg'
import mjaap from './mahamrityumJaap.jpg'
import rketu from './rahuKetuPooja.jpg'
import bhatPuja from './मंगल-भात-पूजन.jpg'
import pitraDosh from './pitraDosh.jpg'
import rudraAbhishek from './rudraAbhishek.jpg'
import laghuRudra from './laghuRudraAbhishek.jpg'
import mjaap1 from './mJaap1.jpg'
import rnMukti from './rinMukti.jpg'

// Pooja full Images
import fkaalSarp from './fullImages/KaalSarp.jpeg';
import fgrahShanti from './fullImages/grahShanti.jpeg'
import fchandra from './fullImages/chandraShanti.jpeg'
import fmabagla from './fullImages/maa_baglamukji_jaap_havan.jpeg'
import fmjaap from './fullImages/mahamrityumJaap.jpeg'
import frketu from './fullImages/rahuKetuPooja.jpeg'
import fbhatPuja from './fullImages/मंगल-भात-पूजन.jpeg'
import fpitraDosh from './fullImages/pitraDosh.webp'
import frudraAbhishek from './fullImages/rudraAbhishek.webp'
import flaghuRudra from './fullImages/laghuRudraAbhishek.webp'
import fmjaap1 from './fullImages/mJaap1.jpg'
import frnMukti from './fullImages/rinMukti.jpeg'


const ImageObj= {
    11:mjaap,
    10:grahShanti,
    9:rketu,
    8:mabagla,
    7:chandra,
    1:kaalSarp,
    0:bhatPuja,
    2:pitraDosh,
    3:rudraAbhishek,
    4:laghuRudra,
    5:mjaap1,
    6:rnMukti
}

export const FullPoojaImages = {
    11:fmjaap,
    10:fgrahShanti,
    9:frketu,
    8:fmabagla,
    7:fchandra,
    1:fkaalSarp,
    0:fbhatPuja,
    2:fpitraDosh,
    3:frudraAbhishek,
    4:flaghuRudra,
    5:fmjaap1,
    6:frnMukti
}
export default ImageObj
